import {
  Button,Heading,Image,Spinner,StyleguideProvider,VStack
} from "@verified/backoffice-styleguide";
import { IdentityService,useIdentity } from "@verified/sspa-core-lib";

export default function Root(props: any) {
  const [session] = useIdentity(false);

  console.log("Login App got Session", session);
  if (session === false) return (<Spinner />);
  if (session?.user) return null;

  return (
    <StyleguideProvider>
      <VStack>
        <Heading>Authentication</Heading>
        <Image
          width={280}
          src="https://dev-verified.eu/legacy-theme/common/src/apps/legacy-app/lib/_main/www/assets/login-SSO.png"
        />
        <Button onClick={() => IdentityService.getInstance().signIn()}>
          Login
        </Button>
      </VStack>
    </StyleguideProvider>
  );
}
